@font-face {
    font-family: 'Pacifico';
    src: url('./assets/fonts/Pacifico-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* To ensure it stays in the background */
}

.dailydrive-logo {
    position: absolute;
    top: 30%; /* 1/4 down the page */
    left: 50%;
    width: 35vw; /* 1/4 of the viewport width */
    height: 35vw; /* 1/4 of the viewport height */
    max-width: 200px;
    max-height: 200px;
    transform: translate(-50%, -50%);
}

.schedule-wrapper {
    position: absolute;
    top: 75%; /* 3/4 down the page */
    left: 50%;
    transform: translate(-50%, -50%);
}

.help-wrapper {
    position: absolute;
    top: 85%; /* 3/4 down the page */
    left: 50%;
    transform: translate(-50%, -50%);
}

.privacy-button {
    position: fixed;
    top: 20px; /* Adjust as needed */
    left: 2.5vw; /* Adjust as needed */
    cursor: pointer;
    z-index: 1000; /* Ensure it's above other elements */
}

    .privacy-button img {
        height: 6vh; /* Adjust as needed */
        max-width: 50px;
    }

.googleplay-button {
    position: fixed;
    top: 20px; /* Adjust as needed */
    right: 2.5vw; /* Adjust as needed */
    cursor: pointer;
    z-index: 1000; /* Ensure it's above other elements */
}
    .googleplay-button img {
        width: 15vw; /* Adjust as needed */
        max-width: 125px;
        height: auto; /* Maintain aspect ratio */
    }

.appstore-button {
    position: fixed;
    top: 20px; /* Adjust as needed */
    right: 20vw; /* Adjust as needed */
    max-right: 10px;
    cursor: pointer;
    z-index: 1000; /* Ensure it's above other elements */
}
    .appstore-button img {
        width: 15vw; /* Adjust as needed */
        max-width: 125px;
        height: auto; /* Maintain aspect ratio */
    }

.button {
    padding: 10px 20px;
    font-size: 2vh;
    font-family: 'Pacifico', sans-serif;
    cursor: pointer;
    width: 25vw; /* 1/4 of the viewport width */
    min-width: 200px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
}

    .button:hover {
        background-color: #0056b3;
    }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background-color: white;
    padding: 2vw;
    border-radius: 5px;
    width: 80%;
    height: auto;
    max-width: 500px;
    max-height: 80vh;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
}

.popup-header {
    font-family: 'Pacifico', sans-serif;
    font-size: 2vh;
    margin-bottom: 1vw;
    text-align: center;
}

.popup-body {
    font-size: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 95%; /* Ensure it takes full width of the popup */
    padding: 2vh 20vw;
    line-height: 1.6;
    flex: 1;
    text-align: left;
}

.popup-button {
    padding: 10px 20px;
    font-size: 2vh;
    font-family: 'Pacifico', sans-serif;
    cursor: pointer;
    width: 25vw; /* 1/4 of the viewport width */
    min-width: 200px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
}

    .popup-button:hover {
        background-color: #0056b3;
    }
